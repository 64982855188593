<template>
  <div>
    <!-- 更新硬碟 -->
    <update-modal
      v-if="selected"
      ref="updateModal"
      :device-data-item="selected"
      @refetch-data="refetchTable"
    />

    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              硬碟列表
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
                @click="onSubmitUpdate(null)"
              >
                <b-img
                  src="/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="refetchTable"
              >
                <b-img
                  src="/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <!-- <label class="text-nowrap">顯示</label> -->
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="tableData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        :busy="isBusy"
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: 綁定設備 -->
        <template #cell(pc_id)="data">
          <div class="table-col btn-image-device-bind">
            <b-link
              v-if="data.item.pc_id"
              class="font-weight-bold d-block text-nowrap"
              :to="{ name: 'admin-device-pc-view', params: { id: data.item.pc_id }, query: { type: 'disk' } }"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                :title="`${data.item.device_info ? data.item.device_info.name : ''}`"
                :src="require('@/assets/images/icons/computer.svg')"
                class="btn-image-bind"
                rounded
              />
            </b-link>

            <div v-else>
              <b-img
                :src="require('@/assets/images/icons/computer.svg')"
                class="btn-image-empty"
                rounded
              />
            </div>
          </div>
        </template>

        <!-- 欄位: 名稱 -->
        <template #cell(name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap item-description"
            @click="onSubmitUpdate(data.item)"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">
            <div class="item-description">
              {{ data.item.serialNumber }}
            </div>
          </small>
        </template>

        <!-- 欄位: 說明 -->
        <template #cell(description)="data">
          <div
            class="table-col item-description"
            @click="onSubmitShowDescription(data.item)"
          >
            {{ data.item.description ? data.item.description : '---' }}
          </div>
        </template>

        <!-- 欄位: 品牌 -->
        <template #cell(brand)="data">
          <div
            v-b-tooltip.hover.focus.v-secondary
            :title="`${data.item.brand_id && data.item.brand_info.description ? data.item.brand_info.description : ''}`"
            class="table-col"
          >
            {{ data.item.brand_id ? data.item.brand_info.name : '---' }}
          </div>
        </template>

        <!-- 欄位: 內存大小 -->
        <template #cell(size)="data">
          <div class="table-col text-nowrap">
            {{ data.item.size ? `${parseInt(data.item.size, 10).toLocaleString()} GB` : '---' }}
          </div>
        </template>

        <!-- 欄位: 健康度 -->
        <template #cell(health)="data">
          <div class="d-flex align-items-center table-col">
            <span class="text-body-heading mr-50 text-nowrap health-text">{{ data.item.health }} %</span>
            <vue-apex-charts
              v-b-tooltip.hover.focus.v-secondary
              :title="`${data.item.health} / 100`"
              type="radialBar"
              height="30"
              width="30"
              :options="data.item.chartDataAll.options"
              :series="data.item.chartDataAll.series"
            />
          </div>
        </template>

        <!-- 欄位: 購買日期 -->
        <template #cell(purchase_date)="data">
          <div style="min-width: 85px;">
            {{ data.item.purchase_date ? moment(data.item.purchase_date).format('YYYY-MM-DD') : '---' }}
          </div>
        </template>

        <!-- 欄位: 上次更新 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.updated_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 新增時間 -->
        <template #cell(created_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.created_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.created_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.created_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <!-- ="data" -->
          <div class="d-flex mb-50">
            <div
              class="actions-link-btn mr-25"
              @click="onSubmitUpdate(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="編輯"
                src="/admin/images/table/edit.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              class="actions-link-btn mr-25"
              @click="onSubmitDelete(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="刪除"
                src="/admin/images/table/delete.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>

    <!-- 新增部門 -->
    <!-- <add-modal
      @refetch-data="refetchTable"
    /> -->

    <!-- 編輯部門 -->
    <!-- <edit-modal
      v-if="selected"
      ref="editModal"
      :device-data-item="selected"
      @refetch-data="refetchTable"
    /> -->

    <b-modal
      v-if="selected"
      id="description-modal"
      centered
      hide-footer
    >
      <template #modal-title>
        <h4 class="m-0">
          詳細說明
        </h4>
      </template>

      <div>
        <div class="description-title text-primary">
          {{ selected.name }}
        </div>

        <div class="description-content">{{ selected.description }}
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
// API
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BLink,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'

// Component
import { useDeviceDiskList, useDeviceSetting } from '../useDevice'
import UpdateModal from './DeviceListUpdateModal.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BLink,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    // BDropdownItem,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    UpdateModal,
    VueApexCharts,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.select && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.searchable && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.searchable)
    },
    authAbility() {
      return this.$store.state.app.ability
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)顯示說明
    onSubmitShowDescription(item) {
      if (!item.description) return
      this.selected = item
      setTimeout(() => { this.$bvModal.show('description-modal') }, 200)
    },

    // (觸發)新增/編輯硬碟
    onSubmitUpdate(item) {
      if (!item) {
        this.selected = {}
        setTimeout(() => { this.$refs.updateModal.getData() }, 200)
        return
      }
      this.selected = item
      setTimeout(() => { this.$refs.updateModal.getData() }, 200)
    },

    // (刪除)硬碟
    onSubmitDelete(item) {
      // if (this.authAbility.user !== 'write') return
      this.useSwalAlertWarning('刪除硬碟', `你確定要永久刪除硬碟 ${item.name} 嗎?`)
        .then(result => {
          if (result.value) {
            this.setDeviceDiskDelete({
              disk_id: item.id,
            }).then(() => {
              this.getDeviceDiskListData()
              this.useSwalAlertCenter(true, '刪除成功!', '硬碟已移除')
            })
              .catch(error => {
                this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
              })
          }
        })
    },
  },
  setup() {
    // 註冊模組
    const DEVICE_ADMIN_STORE_MODULE_NAME = 'admin-device'

    if (!store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DEVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_ADMIN_STORE_MODULE_NAME)
    })

    const {
      isBusy,
      tableData,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,
      timeRange,

      setDeviceDiskDelete,
      getDeviceDiskListData,
    } = useDeviceDiskList()

    const {
      ui,
      statusOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDeviceSetting()

    const {
      useAlertToast,
    } = useAlert()

    getDeviceDiskListData()

    return {
      ui,
      isBusy,
      tableData,
      useAlertToast,
      statusOptions,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchTable,
      timeRange,

      setDeviceDiskDelete,
      getDeviceDiskListData,
      refonlineTime,
      onlineTime,
      updateOnline,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.item-description {
  // background-color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  // font-size: 1.4rem;
}

.device-name {
  font-size: 18px;
  margin-bottom: 8px;
}

.device-description {
  white-space: pre-wrap;
}
.health-text {
  min-width: 3em;
  text-align: right;
}
.btn-image-device-bind {
  img {
    height: 30px;
    width: 30px;
  }
  .btn-image-bind:hover {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.2);
  }

  .btn-image-empty {
    opacity: 0.3;
  }
}
.description-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.description-content {
  white-space: pre-wrap;
}
</style>
